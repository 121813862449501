<div class="form-floating">
  <input
    nvInput
    [type]="visible ? 'text' : 'password'"
    [name]="id"
    [id]="id"
    [attr.data-cy]="id"
    autocomplete="off"
    [placeholder]="placeholder"
    (blur)="onTouched()"
  />
  <label [for]="id">{{ placeholder }}</label>

  <small class="cursor-pointer" (click)="togglePassword()"
    >{{ 'LABEL.PASSWORD' | translate }} {{ visible ? ('LABEL.HIDE' | translate) : ('LABEL.SHOW' | translate) }}</small
  >
  <div class="position-absolute end-0 cursor-pointer me-2 mt-2 top-0" (click)="togglePassword()">
    <i class="fas" [class.fa-eye]="visible" [class.fa-eye-slash]="!visible"></i>
  </div>
</div>
