<footer class="mt-3">
  <nv-container class="border-top border-primary">
    <div class="row py-3">
      <div class="col-12 d-md-flex d-lg-block justify-content-md-center col-lg-5">
        <ul>
          <li>
            <nv-link href="https://www.nuernberger.de/impressum/">
              {{ imprintHref }}
            </nv-link>
          </li>
          <li>
            <nv-link href="https://www.nuernberger.de/datenschutz/">
              {{ privacyHref }}
            </nv-link>
          </li>
          <!-- additional navigation items, only visible for viewports smaller than lg -->
          <li class="d-block d-lg-none">
            <nv-link href="assets/documents/evox_Kundenportal_Nutzungsbedingungen.pdf">
              {{ termsOfUseHref }}
            </nv-link>
          </li>
          <li class="d-block d-lg-none">
            <nv-link href="assets/documents/evox-erstinformation.pdf">
              {{ initialContactDetailsHref }}
            </nv-link>
          </li>
        </ul>
      </div>
      <div class="col-12 col-lg-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="nv-footer-signet" width="45" height="45" viewBox="0 0 66 66">
          <path
            fill="#003460"
            d="M36.48,38.3l0.7,1.3c0,0.1,0,0.1,0,0.2v7c0,0.1,0,0.1,0.1,0.2l5.17,4.2c0.1,0.1,0.2,0.1,0.3,0.1h7.45c0.1,0,0.2-0.1,0.2-0.2V39.9c0-0.1,0-0.1,0-0.2l0.7-1.3c0.1-0.1,0-0.3-0.2-0.3H36.68C36.48,38,36.38,38.1,36.48,38.3z M16.5,28.2v1.4c0,0.1,0,0.1,0,0.2l1.59,3.8c0,0.1,0,0.1,0,0.2v10.6c0,0.1,0.1,0.2,0.2,0.2H32.7c0.1,0,0.2-0.1,0.2-0.2V33.8c0-0.1,0-0.1,0-0.2l1.59-3.8c0-0.1,0-0.1,0-0.2v-1.4c0-0.1-0.1-0.2-0.2-0.2H16.5C16.6,28,16.5,28.1,16.5,28.2z M30.12,15.3c0.2,0,0.3-0.2,0.2-0.3l-4.57-6c-0.1-0.1-0.3-0.1-0.3,0l-4.57,6c-0.1,0.1,0,0.3,0.2,0.3H30.12z M35.78,24.9c0.2,0,0.3-0.2,0.1-0.4l-6.16-6.2l-0.1-0.1h-8.05c-0.1,0-0.1,0-0.1,0.1l-6.16,6.2c-0.1,0.1,0,0.4,0.1,0.4H35.78z M51.09,34.7l-1.39-3.2c-0.1-0.2-0.3-0.2-0.4,0l-1.29,3c-0.1,0.2-0.3,0.2-0.4,0l-3.78-9c-0.1-0.2-0.3-0.2-0.4,0l-3.78,9c-0.1,0.2-0.3,0.2-0.4,0l-1.29-3c-0.1-0.2-0.3-0.2-0.4,0l-1.39,3.2c-0.1,0.1,0,0.3,0.2,0.3h14.21C51.09,35,51.19,34.9,51.09,34.7z M62.72,33c0,8.4-3.48,16-9.24,21.5H41.35c-0.1,0-0.2,0-0.3-0.1L33,48c-0.1-0.1-0.2-0.1-0.3-0.1H7.26c-2.48-4.4-3.98-9.4-3.98-14.8C3.28,16.7,16.6,3.4,33,3.4C49.4,3.3,62.72,16.6,62.72,33zM66,33C66,14.8,51.29,0,33,0S0,14.8,0,33s14.71,33,33,33S66,51.2,66,33z"
          />
        </svg>
      </div>
      <!-- additional navigation items, included in first list for all viewports smaller than lg -->
      <div class="d-none d-lg-block col-lg-5 nv-footer-additional-nav">
        <ul>
          <li>
            <nv-link href="assets/documents/evox_Kundenportal_Nutzungsbedingungen.pdf">
              {{ termsOfUseHref }}
            </nv-link>
          </li>
          <li>
            <nv-link href="assets/documents/evox-erstinformation.pdf">
              {{ initialContactDetailsHref }}
            </nv-link>
          </li>
        </ul>
      </div>
    </div>
  </nv-container>
</footer>
