<div class="form-check form-{{ appearance }}">
  <input
    type="checkbox"
    class="form-check-input"
    [attr.id]="id"
    [attr.data-cy]="id"
    [attr.name]="name"
    [attr.value]="value"
    [required]="required"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onChange($event.target.checked)"
    (blur)="onTouched()"
  />
  <label class="form-check-label" [attr.for]="id"><ng-content></ng-content></label>
</div>
