<button
  nv-button
  variant="outline"
  color="primary"
  id="pathAdviceCustomButton"
  class="rounded-circle p-0"
  (click)="pathAdviceConnect()"
>
  <i class="fas fa-comment-dots"></i>
</button>
