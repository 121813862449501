<nv-container *ngIf="enableConstructionPage">
  <nv-construction-page></nv-construction-page>
</nv-container>
<ng-container *ngIf="!enableConstructionPage">
  <nv-header></nv-header>

  <section class="content">
    <nv-container>
      <h3 class="mb-3">{{ 'APP.TITLE' | translate }}</h3>
      <router-outlet></router-outlet>
    </nv-container>
  </section>
  <nv-footer
    [imprintHref]="'APP.FOOTER.IMPRINT' | translate"
    [initialContactDetailsHref]="'APP.FOOTER.INITIAL_CONTACT_DETAILS' | translate"
    [privacyHref]="'APP.FOOTER.PRIVACY' | translate"
  ></nv-footer>
</ng-container>
