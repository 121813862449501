<a
  [href]="href"
  target="{{ targetBlank ? '_blank' : '_self' }}"
  rel="noopener"
  class="text-secondary"
  [style.color]="type === 'default' ? 'red' : 'inherit'"
>
  <i *ngIf="type === 'download'" class="far fa-file-pdf fa-lg me-1"></i>
  <ng-content></ng-content>
</a>
