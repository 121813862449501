<div class="input-group form-floating" [class.form-floating]="floatingLabel">
  <input
    ngbDatepicker
    class="date-picker-input form-control"
    [class.with-toggle]="showToggle"
    placeholder="{{ placeholder }}"
    #datepicker="ngbDatepicker"
    id="{{ id }}-custom"
    attr.data-cy="{{ id }}-custom"
    [maxDate]="maxNgbDate"
    [minDate]="minNgbDate"
    (change)="onInputChange($event.target?.value)"
    (dateSelect)="onNbgDatepickerChange($event)"
    (blur)="onTouched()"
    [ngModel]="value"
    [markDisabled]="allowOnlyFirstOfMonth()"
    [readonly]="readOnly"
  />
  <label *ngIf="floatingLabel" for="{{ id }}-custom">{{ placeholder }}</label>
  <div *ngIf="showToggle" class="input-group-append">
    <button nv-button variant="outline" type="button" (click)="datepicker.toggle()">
      <i class="far fa-calendar-alt"></i>
    </button>
  </div>
</div>
