<div class="nv-progress-nav" [ngClass]="{ 'bg-primary-35 shadow': fixed }" [class.fixed-top]="fixed">
  <nv-container
    ><p class="mb-0 mt-1 d-block d-lg-none">
      <strong>{{ activeItem?.title }}</strong>
    </p>
    <ul class="mb-0 mb-lg-3" [ngClass]="'active-item-' + activeItem?.index">
      <ng-content></ng-content>
    </ul>
  </nv-container>
</div>
