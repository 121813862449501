<div class="form-check form-{{ appearance }}">
  <input
    type="radio"
    class="form-check-input"
    [attr.name]="name"
    [attr.id]="id"
    [attr.value]="value"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onChange()"
  />
  <label class="form-check-label" [for]="id"><ng-content></ng-content></label>
  <i
    *ngIf="checked && (appearance === 'tile' || appearance === 'inline-tile')"
    class="check-icon fas fa-check-circle"
  ></i>
</div>
