import { Component, HostBinding, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'nv-ewe-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  @HostBinding('class.nv-evox-root') rootClass = true;

  constructor(
    private translateService: TranslateService,
    @Inject('enableConstructionPage') public enableConstructionPage: boolean
  ) {
    this.translateService.setDefaultLang('de');
  }
}
