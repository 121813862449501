<div class="nv-loadingspinner" [hidden]="!alwaysVisible && (showSpinner$ | async) === false">
  <ng-content></ng-content>
  <div class="loader d-block mx-auto">
    <div class="loader-circle"></div>
    <div class="loader-content">
      <div class="loader-package">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</div>
