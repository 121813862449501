<nv-card class="nv-collapse tile" [ngClass]="{ 'border-primary-1': hasBorder, 'border-0': !hasBorder }">
  <div
    #collapseController
    [ngClass]="{ show: !isCollapsed }"
    class="cursor-pointer"
    (click)="isCollapsed = !isCollapsed"
  >
    <ng-content select="[collapseController]"></ng-content>
  </div>
  <h3
    *ngIf="collapseController.children.length === 0"
    class="nv-collapse-controller cursor-pointer"
    [ngClass]="{ show: !isCollapsed }"
    (click)="isCollapsed = !isCollapsed"
  >
    <span class="far fa-angle-down"></span>
    {{ title }}
  </h3>
  <div id="{{ id }}" attr.data-cy="{{ id }}" class="collapse" [ngClass]="{ show: !isCollapsed }">
    <ng-content></ng-content>
  </div>
</nv-card>
