<nav class="fixed-top">
  <ul class="text-center d-flex justify-content-center">
    <li *ngFor="let item of items">
      <strong class="d-flex">
        <a id="{{ item.id }}" attr.data-cy="{{ item.id }}" [routerLink]="item.route"
          ><i class="{{ item.icon }}"></i>
          {{ item.title | translate }}
        </a>
      </strong>
    </li>
  </ul>
</nav>
