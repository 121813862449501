<nv-card [fullHeight]="true" class="tile pe-2 pt-0 h-100 overflow-hidden">
  <div class="d-flex flex-column h-100">
    <div class="row">
      <div class="nv-card-content col-lg-5 col-12 order-2 order-lg-1">
        <ng-content select="[nv-card-content]"></ng-content>
      </div>
      <div class="nv-card-image col-lg-7 col-12 order-1 order-lg-2">
        <ng-content select="[nv-card-image]"></ng-content>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</nv-card>
