<span
  class="nv-popover-anchor d-block"
  [ngbPopover]="popoverContent"
  [placement]="placement"
  autoClose="outside"
  (hidden)="closed.emit()"
  container="body"
>
  <ng-content select="[nv-popover-anchor]"></ng-content>
</span>
<ng-template #popoverContent>
  <nv-card class="overlay border-0 nv-popover">
    <div class="popover-header">
      <button type="button" class="btn btn-light nv-handler-close py-1" aria-label="Close" (click)="close()">
        <span aria-hidden="true" class="far fa-times"></span>
      </button>
    </div>
    <div class="popover-body">
      <ng-container *ngTemplateOutlet="lazyContent"></ng-container>
    </div>
  </nv-card>
</ng-template>
