<nv-container>
  <div class="row align-items-center">
    <div class="col-12 col-md-5 col-lg-4 order-1 order-md-0">
      <ng-content></ng-content>
    </div>
    <div
      class="col-12 col-md-7 col-lg-8 d-flex align-items-center justify-content-between justify-content-md-end order-0 order-md-1 mb-2 mb-md-0"
    >
      <nv-info-text-container>
        <p class="d-inline-block lh-100 mb-0">
          <ng-content select="[amount-info-text]"></ng-content>
        </p>
        <ng-content select="[price-info-text]"></ng-content>
      </nv-info-text-container>
      <h2 class="d-inline-block mb-0 ms-2">{{ amount }}</h2>
      <ng-content select="[info-container]"></ng-content>
    </div>
  </div>
</nv-container>
