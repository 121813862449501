<div class="mt-2">
  <div class="row">
    <div class="col-7">
      <p>{{ 'PRICING.PAGE.CONTRACT.CURRENT_PRICE' | translate }}</p>
    </div>
    <div class="col-5">
      <h3 class="float-end">{{ currentPrice | currency: '€':'symbol':'1.2' }}</h3>
    </div>
  </div>
</div>
