<div class="dropdown form-floating">
  <input
    nvInput
    [id]="id"
    [attr.data-cy]="id"
    [inputFormatter]="formatter"
    [ngbTypeahead]="search"
    [resultFormatter]="formatter"
    class="form-control"
    autocomplete="new-password"
    focusFirst="true"
    placeholder="{{ placeholder }}"
    [readOnly]="readOnly"
  />
  <label [for]="id">
    {{ placeholder }}
  </label>
  <nv-form-info-container>
    <ng-content select="[info-modal]"></ng-content>
  </nv-form-info-container>
</div>
