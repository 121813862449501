<div class="row nv-button-row d-flex flex-wrap-reverse align-items-end">
  <div
    [ngClass]="{ 'pt-2 pt-md-0': trustElement }"
    *ngIf="backLink"
    class="col-12 col-md-6 d-flex justify-content-start"
  >
    <a
      nv-button
      [color]="backwardColor"
      variant="flat"
      [routerLink]="[backLink]"
      [title]="'BTN.BACK' | translate"
      [nvActionName]="trackBackAction && backTitle"
    >
      <i class="far fa-arrow-left me-1" *ngIf="showBackArrow"></i>{{ backTitle }}
    </a>
  </div>
  <div
    [ngClass]="{ 'col-12 col-md-6': backLink, 'col-12': !backLink }"
    class="d-flex flex-column align-items-start align-items-md-end justify-content-sm-start justify-content-md-end"
  >
    <button
      nv-button
      data-cy="submit"
      [color]="forwardColor"
      class="px-3 mb-1"
      type="submit"
      [disabled]="forwardDisabled"
      [nvActionName]="trackForwardAction && forwardTitle"
      [dynamic]="true"
    >
      {{ forwardTitle }}
    </button>
    <small *ngIf="trustElement" class="trust-element pe-1 text-primary-2"
      ><i class="fal fa-lock me-1"></i> {{ 'LABEL.TLS_ENCRYPTION' | translate }}</small
    >
  </div>
</div>
