<p>
  <small>
    <ng-content select="[securityNote]"></ng-content>
  </small>
</p>
<p>
  <strong>
    <ng-content select="[security]" passwordStrength$></ng-content>
  </strong>
</p>
<nv-progress-bar
  color="{{ (strengthStatus$ | async).color }}"
  size="{{ (strengthStatus$ | async).size }}"
></nv-progress-bar>
