<nv-modal #modalDecisionChange [isClosableByBackdrop]="false" (closed)="modalClosed$.next(null)">
  <ng-template modalContent>
    <ng-content></ng-content>
    <div class="col-12 d-flex justify-content-end">
      <button class="btn-light me-2" (click)="dismissSuccessModal()" nv-button type="submit">
        {{ dismissBtnText }}
      </button>
      <button nv-button type="submit" (click)="modalConfirmed$.next(true)">
        {{ submitBtnText }}
      </button>
    </div>
  </ng-template>
</nv-modal>
