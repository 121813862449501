import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { NvConstructionPageModule, NvContainerModule, NvFooterModule, NvHeaderModule } from '@nbg-digital/ui-dpl-components';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { HttpLoaderFactory } from '@nbg-digital/shared/util';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NvFooterModule,
    NvContainerModule,
    NvHeaderModule,
    NvConstructionPageModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule,
  ],
  providers: [
    {
      provide: 'eweApiUrl',
      useValue: environment.eweApiUrl,
    },
    {
      provide: 'apiToken',
      useValue: environment.apiToken,
    },
    {
      provide: 'enableConstructionPage',
      useValue: environment.enableConstructionPage,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
