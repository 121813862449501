<nv-header *ngIf="showHeader"></nv-header>
<nv-container>
  <div class="mt-5 text-center">
    <h2>{{ 'SERVICE_ERROR_PAGE.TITLE' | translate }}<i class="fal fa-frown"></i></h2>
    <h3>
      <br />
      {{ 'SERVICE_ERROR_PAGE.TEXT' | translate }}
    </h3>
  </div>
</nv-container>
