<div class="nv-dropdown form-floating" ngbDropdown display="static">
  <input
    type="text"
    ngbDropdownToggle
    readonly="readonly"
    placeholder="{{ placeholder }}"
    id="{{ id }}"
    attr.data-cy="{{ id }}"
    [value]="label"
    [disabled]="disabled"
    class="form-control"
    (blur)="onBlur()"
  />
  <div ngbDropdownToggle class="dropdown-toggle-indicator">
    <span class="far fa-angle-down"></span>
  </div>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic" class="dropdown-menu">
    <ng-content></ng-content>
  </div>
  <label [for]="id">
    {{ placeholder }}
  </label>
  <nv-form-info-container>
    <ng-content select="[info-modal]"></ng-content>
  </nv-form-info-container>
</div>
