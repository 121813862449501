<div
  #nvSlider
  [style.background]="
    enableHighlightedRange
      ? 'linear-gradient(90deg, var(--nv-primary-2)' +
        highlightedRangePosition +
        '%, var(--nv-secondary)' +
        highlightedRangePosition +
        '%'
      : 'var(--nv-primary-2)'
  "
></div>
