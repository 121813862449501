<button (click)="this.modalAgent.open()">
  <nv-modal #modalAgent>
    <ng-template modalContent>
      <div *ngIf="showForm">
        <div nv-action-area="overlay">
          <h3 class="mb-2" [innerHTML]="'APP.HEADER.POPUP_TITLE' | translate"></h3>
          <h4 class="mb-2">{{ 'CONSULTANT.ADDRESS.COMPANY' | translate }}</h4>
          <p>
            {{ 'CONSULTANT.ADDRESS.STREET' | translate }}<br />{{ 'CONSULTANT.ADDRESS.ZIP_CODE_LOCATION' | translate }}
          </p>
          <h4>{{ 'CONSULTANT.REACHABILITY.REACHABILITY_BY_PHONE' | translate }}</h4>
          <p>
            <span class="popover-inner-span">{{ 'CONSULTANT.REACHABILITY.DAYS' | translate }}</span
            >{{ 'CONSULTANT.REACHABILITY.HOURS' | translate }}
          </p>
          <a
            href="tel:+498005318119"
            class="d-block link-secondary mb-2"
            [nvActionName]="'CONSULTANT.PHONE_NUMBER' | translate"
            [dynamic]="true"
          >
            <i class="fas fa-phone fa-rotate-90 me-1"></i>{{ 'CONSULTANT.PHONE_NUMBER' | translate }}</a
          >

          <nv-link href="assets/documents/evox-erstinformation.pdf">
            {{ 'APP.HEADER.FIRST_CONTACT_INFORMATION_DOWNLOAD' | translate }}
          </nv-link>
        </div>
      </div>
    </ng-template>
  </nv-modal>
</button>
