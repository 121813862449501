<nv-card class="bg-primary-3 border-primary-2 p-2 p-md-3">
  <div class="d-flex flex-nowrap align-items-center">
    <i
      class="display-4 fal text-error flex-shrink-1"
      [class.text-secondary]="type === 'info'"
      [class.text-error]="type === 'alert'"
      [class.fa-lightbulb]="type === 'info'"
      [class.fa-lightbulb-exclamation]="type === 'alert'"
    ></i>
    <p class="mb-0 w-100 ms-2">
      <ng-content></ng-content>
    </p>
  </div>
</nv-card>
