<div class="nv-info-modal" (click)="openDialog($event)">
  <i class="fad fa-info-circle"></i>

  <nv-modal #modal>
    <ng-template modalContent>
      <ng-container *ngTemplateOutlet="lazyContent"></ng-container>
    </ng-template>
    <ng-content></ng-content>
  </nv-modal>
</div>
