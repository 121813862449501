<button
  ngbDropdownItem
  type="button"
  class="dropdown-item"
  id="{{ id }}-button"
  attr.data-cy="{{ id }}-button"
  (click)="onSelect()"
  (keydown.Tab)="onTab()"
>
  {{ label }}
</button>
